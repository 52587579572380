 <template>
  <div>
    <CModal color="primary" :show="showModal">
      <template v-slot:header>
        <h5 class="modal-title">User Details</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(showModal)"
        >
          ×
        </button>
      </template>
      <p>
        Name:
        {{ user ? user.first_name + " " + user.last_name : null }}.
      </p>
      <p>
        Email:
        {{ user ? user.email : null }}.
      </p>
      <p>
        Contact No:
        {{ user ? user.contact_no : null }}.
      </p>
      <p>
        Address:
        {{ user ? user.address : null }}.
      </p>
      <p>
        NID Number:
        {{ user ? user.nid_number : null }}.
      </p>
      <p>
        Bank Account:
        {{ user ? user.bank_account : null }}.
      </p>
      <template v-if="user ? user.permissions : null">
        <p> Permissions :
      <CBadge v-for="permission in user.permissions" color="success" :key="permission.id" class="mx-1"
        >{{ permission.name }}
      </CBadge>
        </p>
      </template>

      <template v-slot:footer>
        <CButton color="primary" @click="closeModal(showModal)">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "UserView",
  computed: {
    ...mapState({
      showModal: (state) => state.users.showModal,
      user: (state) => state.users.showUser,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("users/SHOW_MODAL", {
        bool: bool,
        user: null,
      });
    },
  },
};
</script>